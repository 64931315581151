


















import AppLogo from "@/components/AppLogo.vue";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "AuthLayout",
  components: { AppLogo },
});
